/* stylelint-disable keyframes-name-pattern */
@keyframes fadeDown {
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.item {
	& .trigger {
		padding-block: 28px;
		padding-left: 15px;
		padding-right: 15px;
		color: inherit;
		white-space: nowrap;

		@media (hover: hover) {
			&:hover,
			&:hover button {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	&:first-child {
		& .trigger {
			padding-left: 0;
		}
	}

	&:last-child {
		& .trigger {
			padding-right: 0;
		}
	}
}

.content {
	animation-duration: 250ms;
	animation-timing-function: ease;
	padding: 60px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"links story"
		"tags tags";

	& .itemLink {
		color: black;
	}

	& .contentLinks {
		grid-area: links;
		display: flex;
		justify-content: space-between;

		& .contentItem {
			* {
				margin-top: 0;
			}

			display: flex;
			flex-direction: column;
			justify-content: start;

			&[display-mode="all-large"] {
				gap: 16px;

				& .title {
					margin-bottom: 4px;
				}

				& .itemLink {
					@extend %typo-heading-3;

					font-weight: bold;
				}
			}

			&[display-mode="first-large"] {
				gap: 10px;

				& .title {
					@extend %typo-body-2;
				}

				& .itemLink:first-of-type {
					@extend %typo-heading-5;

					font-weight: 600;
					margin-bottom: 10px;
				}
			}
		}
	}

	& .contentTags {
		grid-area: tags;
		margin-top: 64px;
		background-color: var(--primary-greige);
		padding: 20px 30px;
		display: flex;
		gap: var(--space-2xl);
		border-radius: var(--border-radius-sm);

		& .tagsContainer {
			display: flex;
			flex-direction: column;
			gap: 10px;

			& h5 {
				margin: 0;
			}

			& .tags {
				display: flex;
				gap: 15px;
			}
		}
	}
}

.NavigationMenuContent {
	animation-duration: 250ms;
	animation-timing-function: ease;
	width: 100%;
	height: fit-content;
	position: absolute;
	top: 40px;
	left: 0;
	border-radius: var(--space-md);

	&[data-motion="from-start"] {
		/* & .content { */
		animation-name: enterFromLeft;

		/* } */
	}

	&[data-motion="from-end"] {
		/* & .content { */
		animation-name: enterFromRight;

		/* } */
	}

	&[data-motion="to-start"] {
		/* & .content { */
		animation-name: exitToLeft;

		/* } */
	}

	&[data-motion="to-end"] {
		/* & .content { */
		animation-name: exitToRight;

		/* } */
	}
}

@keyframes enterFromRight {
	from {
		opacity: 0;
		transform: translateX(200px);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes enterFromLeft {
	from {
		opacity: 0;
		transform: translateX(-200px);
	}

	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes exitToRight {
	from {
		opacity: 1;
		transform: translateX(0);
	}

	to {
		opacity: 0;
		transform: translateX(200px);
	}
}

@keyframes exitToLeft {
	from {
		opacity: 1;
		transform: translateX(0);
	}

	to {
		opacity: 0;
		transform: translateX(-200px);
	}
}
