/* stylelint-disable keyframes-name-pattern */
.wrapper {
	& li {
		list-style: none;
		cursor: pointer;
	}

	& a {
		text-decoration: none;
		cursor: pointer;
		color: inherit;
	}

	& svg {
		cursor: pointer;
	}
}

.content {
	--category-btn-height: 56px;

	height: var(--header-min-height);
	padding-inline: var(--page-inset);
	display: grid;
	align-items: center;
	z-index: var(--z-sticky);
	grid-template-columns: 1fr 1fr;
	grid-template-areas: "logo actions";

	@media (--lg) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: "logo sections actions";
	}
}

.continueShopping {
	--margin: 0;

	margin: var(--margin);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: inherit;
	grid-column: 3;

	@media (--lg) {
		margin-top: 21px;
		margin-bottom: 20px;
	}

	& .continueShoppingText {
		--size: 11px;

		font-size: var(--size);
		line-height: 1;
		white-space: nowrap;

		@media (--lg) {
			--size: 13px;
		}
	}
}

.logo {
	grid-area: logo;
	margin: 0;

	@media (--lg) {
		margin-top: 21px;
		margin-bottom: 20px;
	}
}

.desktopLinks {
	display: none;

	@media (--lg) {
		display: block;
	}
}

.links {
	height: 100%;
	padding-left: 0;
	display: none;
	align-items: center;
	justify-content: center;
	grid-area: sections;

	@media (--lg) {
		display: flex;
	}

	& a {
		color: var(--color-foreground);
		text-decoration: none;
	}
}

.actions {
	margin: 0;
	display: flex;
	align-items: center;
	gap: 16px;
	grid-area: actions;
	grid-template-areas: "search favourite cart menu";
	justify-self: end;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	& * > svg {
		transition: transform 0.2s ease;

		@media (hover: hover) {
			&:hover {
				transform: scale(1.15);
			}
		}
	}

	@media (--lg) {
		margin-block: 28px;
		gap: 12px;
		grid-template-areas: "search favourite account cart";
	}

	& .action {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .search {
		grid-area: search;
	}

	& .favourite {
		grid-area: favourite;
	}

	& .account {
		display: none;
		grid-area: account;
		color: inherit;

		@media (--lg) {
			display: block;
		}
	}

	& .cart {
		grid-area: cart;
		height: fit-content;
	}

	& .menuToggle {
		height: max-content;
		display: flex;
		align-items: center;
		justify-content: center;
		grid-area: menu;

		&:focus-within {
			outline: 1px solid blue;
		}
	}

	@media (--lg) {
		& .menuToggle {
			display: none;
		}
	}
}

.viewport {
	height: calc(var(--radix-navigation-menu-viewport-height) + 40px);
	position: fixed;
	top: calc(var(--header-height) - 30px);
	left: 20px;
	right: 20px;
	z-index: var(--z-sticky);
	overflow: hidden;
	transition:
		transform 0.2s ease-in-out,
		opacity 0.2s ease-in-out,
		height 0.2s ease-in-out,
		visibility 0.2s ease-in-out;

	&[data-state="open"] {
		visibility: visible;
		pointer-events: all;
		opacity: 1;
		transform: translateY(0);
	}

	&[data-state="closed"] {
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
		transform: translateY(20px);
	}

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		background-color: var(--primary-white);
		margin-top: 20px;
		border-radius: var(--space-md);
	}
}

.contentStory {
	--media-aspect: 16 / 9;

	color: inherit;
	width: 70%;
	margin-top: 30px;
	margin-left: 0;
	margin-right: auto;
	grid-area: story;

	& .contentStoryImage {
		width: 100%;
		aspect-ratio: 16 / 9;
		position: relative;

		& .tag {
			position: absolute;
			bottom: 0;
			left: 0;
			margin: 15px;
		}

		& img {
			border-radius: var(--border-radius-sm);
			object-fit: cover;
		}
	}

	& h4 {
		margin-top: 19px;
	}

	@media (--md) {
		margin-left: auto;
		margin-right: 0;
	}
}

.countryStatus.countryStatus {
	gap: 10px;
}

:global(body:has(.full-bleed)) .wrapper[data-state="top"] {
	border: none;
	background-color: transparent;
	color: var(--primary-white);
}

.wrapper:has([data-state="open"]),
:global(body:has(.full-bleed)) .wrapper:has([data-state="open"]) {
	background-color: var(--primary-white);
	color: var(--primary-dark-blue);
	opacity: 1;
}

/* stylelint-disable-next-line no-descending-specificity */
:global(body.scroll-locked) .wrapper.wrapper {
	transform: translateY(0);
	opacity: 1;
	background-color: var(--primary-white);
	color: var(--color-foreground);
}

:global(body:has(.modal[open])) {
	& .wrapper {
		@media (--max-md) {
			width: 100%;
			position: fixed;
			top: var(--snackbar-visibility);
			left: 0;
			background-color: var(--primary-white);
			color: var(--primary-dark-blue);
			z-index: var(--z-sticky);
			transform: translateY(0);
			opacity: 1;
		}
	}

	/* --modal-offset: var(--header-height); */
}

:global(body:has(.full-bleed)) .root:has([data-state="open"]) .wrapper {
	color: var(--primary-dark-blue);
}
